import React from 'react';

const IconLight = () => (
  <svg
    className="svg-icon"
    overflow="hidden"
    fill="currentColor"
    viewBox="100 120 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg">
    <title>light</title>
    <g transform="scale(1.2)">
      <path
        d="M641.51552 516.58752c0-79.0528-64.32768-143.36-143.36-143.36s-143.36 64.3072-143.36 143.36c0 79.03232 64.32768 143.36 143.36 143.36s143.36-64.32768 
    143.36-143.36z m-143.36 102.4c-56.46336 0-102.4-45.93664-102.4-102.4s45.93664-102.4 102.4-102.4 102.4 45.93664 102.4 102.4-45.93664 
    102.4-102.4 102.4zM477.67552 698.1632v125.62432a20.48 20.48 0 1 0 40.96 0V698.1632a20.48 20.48 0 1 0-40.96 0zM477.67552 209.38752v125.62432a20.48 
    20.48 0 1 0 40.96 0v-125.62432a20.48 20.48 0 1 0-40.96 0zM700.90752 748.27776a20.41856 20.41856 0 0 0 28.95872 0 20.45952 20.45952 0 0 0 0-28.95872L641.024
     630.49728a20.45952 20.45952 0 1 0-28.95872 28.95872l88.84224 88.82176zM266.46528 284.8768a20.45952 20.45952 0 0 0 0 28.95872l88.84224 88.82176a20.41856 
     20.41856 0 0 0 28.95872 0 20.45952 20.45952 0 0 0 0-28.95872l-88.84224-88.82176a20.48 20.48 0 0 0-28.95872 0zM805.35552 496.10752H679.7312a20.48 
     20.48 0 1 0 0 40.96h125.62432a20.48 20.48 0 1 0 0-40.96zM170.47552 516.58752a20.48 20.48 0 0 0 20.48 20.48h125.62432a20.48 20.48 0 1 0 
     0-40.96H190.95552a20.48 20.48 0 0 0-20.48 20.48zM700.90752 284.8768l-88.84224 88.84224a20.45952 20.45952 0 1 0 28.95872 
     28.95872l88.84224-88.84224a20.45952 20.45952 0 1 0-28.95872-28.95872zM280.94464 754.2784a20.41856 20.41856 0 0 0 
     14.47936-6.00064l88.84224-88.82176a20.45952 20.45952 0 1 0-28.95872-28.95872l-88.84224 88.82176a20.45952 20.45952 
     0 0 0 14.47936 34.95936z"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default IconLight;
