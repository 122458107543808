import React from 'react';

const IconDark = () => (
  <svg
    className="svg-icon"
    overflow="hidden"
    fill="currentColor"
    viewBox="-140 -150 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg">
    <title>dark</title>
    <g transform="scale(0.7)">
      <path
        d="M524.769411 1023.897612c-140.171583 0-271.947206-54.573743-371.060495-153.687032s-153.687032-230.888911-153.687032-371.060495c0-115.239676 37.013899-223.977603 106.9973-314.490152 33.532647-43.362064 73.669433-81.143886 119.335267-112.321568 46.024198-31.433657 96.655935-55.444056 150.410559-71.263274 9.829417-2.918108 20.426757 0.358364 26.979702 8.242376s7.730427 18.890911 3.071693 28.0036c-33.020698 64.351965-48.379162 125.683432-48.379162 193.10709 0 239.950405 195.20608 435.156485 435.156485 435.156485 67.423658 0 128.755125-15.358464 193.10709-48.379162 9.112689-4.658734 20.119588-3.481252 28.0036 3.071693s11.109289 17.150285 8.242376 26.979702c-15.870413 53.754625-39.829617 104.386362-71.263274 150.410559-31.177682 45.665833-68.959504 85.80262-112.321568 119.335267-90.512549 69.983402-199.30167 106.9973-314.490152 106.9973zM336.37225 69.881012c-172.731527 73.976602-285.155485 240.052795-285.155485 429.269074 0 261.093891 212.458754 473.552646 473.552646 473.552646 189.216279 0 355.292471-112.475153 429.269074-285.155485-52.423558 19.607639-105.359064 29.181082-160.495951 29.181082-129.932607 0-252.032397-50.580542-343.876013-142.424158s-142.475353-213.994601-142.475353-343.927208c0-55.136886 9.624638-108.072393 29.181082-160.495951z"
        strokeWidth={'5'}
      />
    </g>
  </svg>
);

export default IconDark;
